import axios from "axios";
import React, { useState, useEffect } from "react";
import { FaRegTrashAlt, FaWhatsapp, FaEye, FaPlus, FaPaperPlane, FaEnvelope, FaCheck, FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const InvitadosTabla2 = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idEvento = searchParams.get("id");

  const [invitados, setInvitados] = useState([]);
  const [filteredInvitados, setFilteredInvitados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nuevoInvitado, setNuevoInvitado] = useState({
    Nombre: "",
    Accesos: "",
  });
  const [dataEvento, setDataEvento] = useState({});
  const [totalInvitados, setTotalInvitados] = useState(0);
  const [totalConfirmados, setTotalConfirmados] = useState(0);
  const [totalPendientes, setTotalPendientes] = useState(0);
  const [totalNoAsistiran, setTotalNoAsistiran] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [activeFilter, setActiveFilter] = useState('todos');

  const fetchInvitados = async () => {
    try {
      setLoading(true);
      const responseEvento = await axios.get(`/api/Eventos/${idEvento}`);
      setDataEvento(responseEvento.data.tipoEvento);
      const response = await axios.get(`/api/Invitado/evento/${idEvento}`);
      setInvitados(response.data);
      setFilteredInvitados(response.data);
      setTotalInvitados(response.data.length);
      
      // Calcular totales de confirmados, pendientes y no asistirán
      console.log(response.data);
      const confirmados = response.data.filter(inv => inv.link === "si").length;
      const noAsistiran = response.data.filter(inv => inv.link === "no").length;
      const pendientes = response.data.filter(inv => inv.link === null).length;
      
      setTotalConfirmados(confirmados);
      setTotalNoAsistiran(noAsistiran);
      setTotalPendientes(pendientes);
      
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching invitados:", error);
    }
  };

  const handleFilter = (filter) => {
    setActiveFilter(filter);
    if (filter === 'todos') {
      setFilteredInvitados(invitados);
    } else if (filter === 'confirmados') {
      setFilteredInvitados(invitados.filter(inv => inv.link === "si"));
    } else if (filter === 'pendientes') {
      setFilteredInvitados(invitados.filter(inv => inv.link === null));
    } else if (filter === 'noAsistiran') {
      setFilteredInvitados(invitados.filter(inv => inv.link === "no"));
    }
  };

  const handleAgregarInvitado = async (e) => {
    e.preventDefault();
    setIsAdding(true);
    try {
      const guestId = parseInt(idEvento);
      const invitado = {
        nombre: nuevoInvitado.Nombre,
        accesos: nuevoInvitado.Accesos,
        idEvento: guestId,
        link: nuevoInvitado.link,
        mensajeInvitado: nuevoInvitado.mensajeInvitado,
      };
      const response = await axios.post("/api/Invitado", invitado);
      const newInvitados = [...invitados, response.data];
      setInvitados(newInvitados);
      if (activeFilter === 'todos' || (activeFilter === 'pendientes' && !response.data.link)) {
        setFilteredInvitados([...filteredInvitados, response.data]);
      }
      setTotalInvitados(totalInvitados + 1);
      setTotalPendientes(totalPendientes + 1);
      setNuevoInvitado({ Nombre: "", Accesos: "" });
      setShowForm(false);
    } catch (error) {
      console.error("Error al agregar invitado:", error);
      alert(error.response.data);
    } finally {
      setIsAdding(false);
    }
  };

  const deleteInvitado = async (invitadoId) => {
    const confirmed = window.confirm(
      "¿Está seguro de que desea eliminar este invitado?"
    );
    if (confirmed) {
      try {
        await axios.delete(`/api/Invitado/${invitadoId.idInvitado}`);
        const invitadoEliminado = invitados.find(inv => inv.idInvitado === invitadoId.idInvitado);
        if (invitadoEliminado.link === "si") {
          setTotalConfirmados(totalConfirmados - 1);
        } else if (invitadoEliminado.link === "no") {
          setTotalNoAsistiran(totalNoAsistiran - 1);
        } else {
          setTotalPendientes(totalPendientes - 1);
        }
        const newInvitados = invitados.filter((inv) => inv.idInvitado !== invitadoId.idInvitado);
        setInvitados(newInvitados);
        setFilteredInvitados(filteredInvitados.filter((inv) => inv.idInvitado !== invitadoId.idInvitado));
        setTotalInvitados(totalInvitados - 1);
      } catch (error) {
        console.error("Error al eliminar el invitado:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoInvitado({ ...nuevoInvitado, [name]: value });
  };

  const openMessageModal = (invitado) => {
    setSelectedMessage({
      nombre: invitado.nombre,
      mensaje: invitado.mensajeInvitado
    });
    setShowMessageModal(true);
  };

  useEffect(() => {
    fetchInvitados();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-50 to-pink-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <FaPaperPlane className="mx-auto h-16 w-16 text-indigo-600 animate-bounce" />
          <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            Gestión de Invitados
          </h1>
        </div>

        <div className="mb-8 sm:flex md:block justify-center">
          <button
            onClick={() => setShowForm(!showForm)}
            className="w-full sm:w-auto px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 shadow-lg transform transition hover:-translate-y-0.5"
          >
            <FaPlus className="inline-block mr-2" />
            {showForm ? "Cancelar" : "Agregar Nuevo Invitado"}
          </button>
        </div>

        {showForm && (
          <div className="bg-white rounded-lg shadow-xl p-6 mb-8 transform transition-all">
            <form onSubmit={handleAgregarInvitado} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nombre del Invitado
                </label>
                <input
                  type="text"
                  name="Nombre"
                  value={nuevoInvitado.Nombre}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Número de Accesos
                </label>
                <input
                  type="number"
                  name="Accesos"
                  value={nuevoInvitado.Accesos}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
              <button
                type="submit"
                disabled={isAdding}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {isAdding ? "Agregando..." : "Confirmar"}
              </button>
            </form>
          </div>
        )}

        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="p-4 bg-gray-50 border-b border-gray-200">
            <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-4 text-center">
              <div 
                onClick={() => handleFilter('todos')}
                className={`bg-white p-2 rounded-lg shadow cursor-pointer transform transition hover:scale-105 ${activeFilter === 'todos' ? 'ring-2 ring-gray-400' : ''}`}
              >
                <p className="text-gray-500 text-sm">Total Invitados</p>
                <p className="text-xl font-bold text-gray-700">{totalInvitados}</p>
              </div>
              <div 
                onClick={() => handleFilter('confirmados')}
                className={`bg-white p-2 rounded-lg shadow cursor-pointer transform transition hover:scale-105 ${activeFilter === 'confirmados' ? 'ring-2 ring-green-400' : ''}`}
              >
                <p className="text-green-600 text-sm">Confirmados</p>
                <p className="text-xl font-bold text-green-600">{totalConfirmados}</p>
              </div>
              <div 
                onClick={() => handleFilter('pendientes')}
                className={`bg-white p-2 rounded-lg shadow cursor-pointer transform transition hover:scale-105 ${activeFilter === 'pendientes' ? 'ring-2 ring-yellow-400' : ''}`}
              >
                <p className="text-yellow-600 text-sm">Pendientes</p>
                <p className="text-xl font-bold text-yellow-600">{totalPendientes}</p>
              </div>
              <div 
                onClick={() => handleFilter('noAsistiran')}
                className={`bg-white p-2 rounded-lg shadow cursor-pointer transform transition hover:scale-105 ${activeFilter === 'noAsistiran' ? 'ring-2 ring-red-400' : ''}`}
              >
                <p className="text-red-600 text-sm">No asistirá</p>
                <p className="text-xl font-bold text-red-600">{totalNoAsistiran}</p>
              </div>
            </div>
          </div>

          {loading ? (
            <div className="text-center py-12">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-600 mx-auto"></div>
              <p className="mt-4 text-gray-600">Cargando invitados...</p>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nombre
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Accesos
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Estado
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Mensaje
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredInvitados.slice().reverse().map((invitado) => (
                    <tr key={invitado.idInvitado} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {invitado.nombre}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {invitado.accesos}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {invitado.link === "si" ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            <FaCheck className="mr-1" /> Confirmado
                          </span>
                        ) : invitado.link === "no" ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            <FaTimes className="mr-1" /> No asistirá
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                            <FaTimes className="mr-1" /> Pendiente
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {invitado.mensajeInvitado && (
                          <button
                            onClick={() => openMessageModal(invitado)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            <FaEnvelope className="h-5 w-5" />
                          </button>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <div className="flex space-x-3">
                          <a
                            href={`https://api.whatsapp.com/send?text=${window.location.origin}/share/inv/${invitado.idInvitado}`}
                            className="text-green-600 hover:text-green-900"
                            title="Compartir por WhatsApp"
                          >
                            <FaWhatsapp className="h-5 w-5" />
                          </a>
                          <a
                            href={`/share/inv/${invitado.idInvitado}`}
                            className="text-blue-600 hover:text-blue-900"
                            title="Ver Invitación"
                          >
                            <FaEye className="h-5 w-5" />
                          </a>
                          <button
                            onClick={() => deleteInvitado(invitado)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <FaRegTrashAlt className="h-5 w-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* Modal para mensajes */}
      {showMessageModal && selectedMessage && (
        <div className="fixed z-50 inset-0 overflow-y-auto backdrop-blur-sm">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-black opacity-40"></div>
            </div>
            
            <div className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-2xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border border-gray-100">
              <div className="bg-gradient-to-r from-purple-50 to-pink-50 px-6 pt-6 pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <h3 className="text-xl leading-6 font-semibold text-gray-900 mb-4 font-serif">
                      Mensaje de <span className="text-purple-600">{selectedMessage.nombre}</span>
                    </h3>
                    <div className="mt-4 bg-white p-6 rounded-xl shadow-inner border border-gray-100">
                      <p className="text-base text-gray-600 leading-relaxed font-light">
                        {selectedMessage.mensaje || "Sin mensaje"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white px-6 py-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-full border border-transparent px-6 py-3 bg-gradient-to-r from-purple-600 to-pink-600 text-base font-medium text-white hover:from-purple-700 hover:to-pink-700 shadow-lg transform transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowMessageModal(false)}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvitadosTabla2;
